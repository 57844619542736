<template>
  <div class="z-0">
    <header>
      <ConnectWallet />
    </header>
    <div
      class="flex flex-col items-center justify-start w-full text-yellow-50 p-3 mb-28"
    >
      <div class="py-1 max-w-5xl w-full flex-none">
        <div class="p-4">
          <div>
            <a
              v-on:click="$router.go(-1)"
              class="flex items-center space-x-2 text-center cursor-pointer"
              ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
            >
          </div>
        </div>
        <div class="mb-3">
          <input
            type="text"
            class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-100 bg-gray-800 bg-clip-padding border-solid border-purple-300 border-2 rounded-full transition ease-in-out m-0 focus:text-gray-200 focus:bg-gray-900 focus:border-blue-300 focus:outline-none"
            placeholder="Search"
            v-model="search"
          />
        </div>
      </div>

      <div class="bg-gray-800 py-2 px-3 rounded max-w-5xl w-full flex-none">
        <div class="text-base font-medium">
          <span class="mr-4 font-bold">Latest Transactions</span>
        </div>
      </div>
      <div class="flex-glow max-w-5xl w-full">
        <div v-if="!ready" class="flex flex-col">
          <loader class="h-40"></loader>
          <p class="text-center">Loading...</p>
        </div>
        <div v-else>
          <div v-if="filteredItems.length == 0">
            <div class="flex flex-col">
              <chameleon class="h-40"></chameleon>
              <p class="text-center">You don't have a transaction</p>
            </div>
          </div>
          <div v-else class="w-full items-center">
            <div
              v-for="t in filteredItems"
              v-bind:key="t.id"
              class="flex w-full bg-gray-500 bg-opacity-10 my-1 hover:bg-gray-500 hover:bg-opacity-25 p-2 rounded-md"
            >
              <div class="flex-grow flex flex-col md:flex-row">
                <div class="md:mr-2 text-gray-500 text-sm md:text-base">
                  <router-link
                    class="text-baseline text-blue text-blue-400 mr-4"
                    v-bind:to="'/txrelation/' + t.tx_id"
                    ><i class="fad fa-link mr-1"></i>
                    {{ t.tx_id.slice(0, 6) }}...
                  </router-link>
                  <router-link
                    class="text-baseline mr-4"
                    :style="`color: #${t.user_id.slice(-6)};`"
                    v-bind:to="'/user/' + t.user_id"
                    ><i class="fad fa-user mr-1"></i> ...{{
                      t.user_id.slice(-6)
                    }}
                  </router-link>
                </div>
                <div class="text-sm md:text-base">
                  {{ t.remark }},
                  <span class="text-xs"
                    >fee: {{ fixed(t.fee) }} {{ t.currency }}</span
                  >
                </div>
              </div>
              <div
                class="flex-none text-right flex flex-col md:flex-row text-sm md:text-base"
              >
                <div>
                  {{ fixed(t.amount) }}
                  <img
                    class="inline-block h-4 ml-2"
                    :src="getCurrency[t.currency].logo"
                  />
                </div>
                <div
                  v-bind:class="
                    'text-xs md:inline-block text-center font-semibold mt-1 py-1 rounded uppercase ml-10 md:ml-2 w-24 ' +
                    t.label
                  "
                >
                  {{ t.type }}
                </div>
              </div>
            </div>

            <div class="text-center">
              <div
                class="text-sm mt-2 px-6 py-2 border rounded text-yellow-50 hover:bg-gray-500 hover:bg-opacity-25 border-none cursor-pointer"
                v-on:click="getdata()"
                v-if="!nomore"
              >
                Load more...
              </div>
              <div
                class="text-sm mt-2 px-6 py-2 border rounded text-yellow-50 border-none"
                v-else
              >
                - No more -
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <footer>
        <StickFooter />
      </footer> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import chameleon from "@/assets/svg/chameleon.vue";
import loader from "@/assets/svg/loader.vue";
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import info from "@/api/info";
import func from "@/widgets/func";
import label from "@/widgets/label";
import moment from "moment";
import nativeToast from "native-toast";

export default {
  components: {
    loader,
    chameleon,
    ConnectWallet,
    StickFooter,
  },
  data() {
    return {
      search: "",
      ready: false,
      page: 0,
      nomore: false,
      table_rows: [],
      expanded: null,
    };
  },
  computed: {
    ...mapGetters(["getCurrency"]),
    filteredItems() {
      return this.table_rows.filter((item) => {
        return (
          item.type.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          item.currency.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          item.remark.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          item.tx_id.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },
  methods: {
    fixed(a) {
      return func.fixed(a);
    },
    getdata() {
      info
        .transactions({ page: this.page })
        .then((res) => {
          this.ready = true;
          if (res.data.length == 0) {
            this.nomore = true;
          } else {
            res.data.forEach((e) => {
              var a = label.type(e.type);
              e.label = a.label;

              this.table_rows.push(e);
            });
            this.page = this.page + 1;
          }
        })
        .catch((error) => {
          nativeToast({
            message: error.response.data.error,
            position: "top",
            timeout: 3000,
            type: "error",
          });
        });
    },
  },
  created() {
    this.getdata();

    this.sockets.unsubscribe("txs_feed");
    this.sockets.subscribe("txs_feed", (data) => {
      var a = label.type(data.tx.type);
      data.tx.label = a.label;

      this.table_rows.reverse().push(data.tx);
      this.table_rows.reverse();
    });
  },
};
</script>
